import React from "react"
import { IntlProvider } from "react-intl"
import { IntlContextProvider } from "gatsby-plugin-react-intl"

import { isBrowser } from "./src/helpers/"
import { setupTracking, runPageTracking } from "./src/helpers/tracking"

if (isBrowser()) {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export const onClientEntry = () => {
  setupTracking()
}

export const onRouteUpdate = ({ location }) => {
  runPageTracking(location)
}

export const wrapPageElement = ({ element, props, ...restProps }, ...args) => {
  const intl = props.pageContext.intl
  return (
    <IntlProvider
      locale={intl.language}
      defaultLocale={intl.defaultLocale}
      messages={intl.messages}
    >
      <IntlContextProvider value={intl}>{element}</IntlContextProvider>
    </IntlProvider>
  )
}
