import { ucExcludePaths } from "./config"
import { whenAvailable } from "./"

const debug = process.env.GATSBY_DEBUG_TRACKING === "true"
const ucExcludeVar = "UC_UI_SUPPRESS_CMP_DISPLAY"
const ucScriptTagId = "usercentrics-cmp"
export const thirdpartyServices = {
  youtube: {
    id: "BJz7qNsdj-7",
    name: "YouTube",
  },
}

const ucSetupOverlay = () => {
  const scriptTag = document.createElement("script")
  scriptTag.src = "https://app.usercentrics.eu/browser-ui/latest/loader.js"
  scriptTag.setAttribute("data-settings-id", process.env.GATSBY_USERCENTRICS_ID)
  scriptTag.id = `${ucScriptTagId}`
  document.body.appendChild(scriptTag)

  whenAvailable("UC_UI", () => {
    // Set language of the Usercentric consent manager based on the <html> lang attribute
    // Defaults to German if attribute is not found
    const lang = document.querySelector("html").getAttribute("lang")
    window.UC_UI.updateLanguage(lang || "de")
  })
}

const ucEnableOverlay = () => {
  // enabled UC overlay and open it if it hasn't been interacted with yet
  delete window[ucExcludeVar]
  if (window.localStorage.getItem("uc_user_interaction") !== "true") {
    whenAvailable("UC_UI", () => {
      window.UC_UI.showFirstLayer()
    })
  }
}

export const getConsentStatus = (serviceId, silent) => {
  return new Promise((resolve, reject) => {
    debug &&
      !silent &&
      console.log(`Getting consent for "${thirdpartyServices[serviceId].name}"`)

    whenAvailable(
      "UC_UI",
      () => {
        const status = window.UC_UI?.getServicesBaseInfo().find(
          (service) => service.id === thirdpartyServices[serviceId].id
        )?.consent?.status

        debug &&
          !silent &&
          console.log(
            `Consent for "${thirdpartyServices[serviceId].name}" is ${status}`
          )
        resolve(status)
      },
      () => reject(new Error("Consent object not available."))
    )
  })
}

export const setConsentStatus = (serviceId, silent) => {
  return new Promise((resolve, reject) => {
    debug &&
      !silent &&
      console.log(`Setting consent for "${thirdpartyServices[serviceId].name}"`)

    whenAvailable(
      "UC_UI",
      async () => {
        await window.UC_UI?.acceptService(thirdpartyServices[serviceId].id)
        const result = await getConsentStatus(serviceId)

        debug &&
          !silent &&
          console.log(
            `Consent for "${thirdpartyServices[serviceId].name}" is ${result}`
          )
        resolve(result)
      },
      () => reject(new Error("Consent object not available."))
    )
  })
}
const ucDisableOverlay = () => {
  // disable UC overlay
  window[ucExcludeVar] = true
  whenAvailable("UC_UI", () => window.UC_UI.closeCMP())
}

export const showConsentManager = () => {
  whenAvailable("UC_UI", () => window.UC_UI.showSecondLayer())
}

export const showConsentManagerService = (serviceId) => {
  whenAvailable("UC_UI", () =>
    window.UC_UI.showSecondLayer(thirdpartyServices[serviceId].id)
  )
}

export const setupTracking = () => {
  window.showConsentManager = showConsentManager

  ucSetupOverlay()
}

export const runPageTracking = (location) => {
  // Hide UC overlay on pages that need to be accessible for GDPR compliance
  if (location.pathname.match(ucExcludePaths)) {
    ucDisableOverlay()
  } else {
    ucEnableOverlay()
  }

  // Minimum delay for reactHelmet's requestAnimationFrame
  const delay = Math.max(32, 0)
  const sendPageView = () => {
    const _mtm = window._mtm || []
    _mtm.push({
      event: "pageView",
      pageName: document.title.replace(/(\ \|)(?!.*\ \|).*/, ""), // filter out " | Site Name" at end of title
      pagePath: location.pathname,
    })
  }

  setTimeout(sendPageView, delay)
}
