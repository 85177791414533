import React from "react"
import { Link } from "gatsby"
import { isMatch } from "picomatch"
import { map, omit } from "lodash-es"

// Add roman numerals
export const romanize = (num) => {
  if (isNaN(num)) return NaN
  // prettier-ignore
  const digits = String(+num).split(""),
    key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
      "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
      "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"];
  let roman = "",
    i = 3
  while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman
  return Array(+digits.join("") + 1).join("M") + roman
}

// Shorten text
export const truncate = (str, maxlength) => {
  return str.length > maxlength ? str.slice(0, maxlength - 1) + "…" : str
}

// Detect if we want to preserve this link from Gatsby Link
// Include file directories
export const isProtectedLink = (link) =>
  link.match(/^((mailto|javascript):|\/(documents|downloads))/)

// Detect links that should be handled by Gatsby Link
export const isInternalLink = (link) => link.match(/^\//)

export const handleLinks = (props) => {
  if (isProtectedLink(props.href)) {
    return <a href={props.href}>{props.children}</a>
  }

  if (isInternalLink(props.href)) {
    return <Link to={props.href}>{props.children}</Link>
  }

  // If neither, return an external link with secure attributes
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )
}

const countMatchedKeywords = (keywords1, keywords2) =>
  keywords1.filter((keyword) => keywords2.includes(keyword)).length

// Based on https://github.com/muhajirdev/related-pages
export const relatedPagesByKeywords = (pages, keywords = ["none"]) => {
  return map(pages, (x) => ({
    ...x,
    _score: countMatchedKeywords(x.keywords || ["none"], keywords || ["none"]),
  }))
    .filter((x) => x._score > 0)
    .sort((a, b) => {
      if (a._score > b._score) {
        return -1
      }
      return 1
    })
    .map((x) => omit(x, "_score"))
}

// Get URL parameters
export const getUrlParam = (parameter) => {
  const match = RegExp("[?&]" + parameter + "=([^&]*)").exec(
    window.location.search
  )
  return match && decodeURIComponent(match[1].replace(/\+/g, " "))
}

export const isYoutubeUrl = (url) => {
  return url.match(
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
  )
}

export const getYoutubeId = (url) => {
  return url.match(/v=([^&]*)/)[1]
}

export const isBrowser = () => typeof window !== "undefined"

export const daysFromNow = (days) =>
  new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000)

// Check script availability before executing
export const whenAvailable = (name, callback) => {
  const intervalId = window.setInterval(function () {
    if (window[name]) {
      window.clearInterval(intervalId)
      callback(window[name])
    }
  }, 100)
}

export const removeHtml = (string) => string?.replace(/(<([^>]+)>)/gi, "")

export const isMatchedIgnoredPaths = (ignoredPaths, pathname) =>
  isMatch(pathname, ignoredPaths)

export const removeHash = () => {
  window.history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search
  )
}

export const getHighlightedText = (text, highlight) => {
  // Split on highlight term and include term into parts, ignore case

  const parts = highlight
    ? text?.split(new RegExp(`(${highlight})(?![^<]*>)`, "gi"))
    : null
  const result = `${
    highlight
      ? parts
          ?.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase()
              ? `<mark class="highlightText">${part}</mark>`
              : part
          )
          .join("")
      : text
  }`

  return result
}

export const slugify = (text, separator, preserveDot = false) => {
  const nonWordCharacters = preserveDot === true ? /[^\w.-]+/g : /[^\w-]+/g
  text = text.toString().toLowerCase().trim()

  const sets = [
    { to: "a", from: "[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]" },
    { to: "ae", from: "[Ä]" },
    { to: "c", from: "[ÇĆĈČ]" },
    { to: "d", from: "[ÐĎĐÞ]" },
    { to: "e", from: "[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]" },
    { to: "g", from: "[ĜĞĢǴ]" },
    { to: "h", from: "[ĤḦ]" },
    { to: "i", from: "[ÌÍÎÏĨĪĮİỈỊ]" },
    { to: "j", from: "[Ĵ]" },
    { to: "ij", from: "[Ĳ]" },
    { to: "k", from: "[Ķ]" },
    { to: "l", from: "[ĹĻĽŁ]" },
    { to: "m", from: "[Ḿ]" },
    { to: "n", from: "[ÑŃŅŇ]" },
    { to: "o", from: "[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]" },
    { to: "oe", from: "[ŒÖ]" },
    { to: "p", from: "[ṕ]" },
    { to: "r", from: "[ŔŖŘ]" },
    { to: "s", from: "[ŚŜŞŠ]" },
    { to: "ss", from: "[ß]" },
    { to: "t", from: "[ŢŤ]" },
    { to: "u", from: "[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]" },
    { to: "ue", from: "[Ü]" },
    { to: "w", from: "[ẂŴẀẄ]" },
    { to: "x", from: "[ẍ]" },
    { to: "y", from: "[ÝŶŸỲỴỶỸ]" },
    { to: "z", from: "[ŹŻŽ]" },
    { to: "-", from: "[·/_,:;']" },
  ]

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, "gi"), set.to)
  })

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(nonWordCharacters, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text

  if (typeof separator !== "undefined" && separator !== "-") {
    text = text.replace(/-/g, separator)
  }

  return text
}
