const Config = () => {
  return {
    trackingCookieDomain: process.env.GATSBY_TRACKING_COOKIE_DOMAIN,
    linkPlatformSignup: process.env.GATSBY_LINK_PLATFORM_SIGNUP,
    linkPlatformLogin: process.env.GATSBY_LINK_PLATFORM_LOGIN,
  }
}

export const ucExcludePaths =
  /privacy-policy|privacy-policies|imprint|maintenance|terms|risk|disclaimer|dispute-resolution/

export default Config
